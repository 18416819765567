import { CustomAnswerResumeModel } from './CustomAnswerResumeModel';
import { TimeSlotResumeModel } from './TimeSlotResumeModel';
import { ParticipationStatus } from './ParticipationModel';
import { TagModel } from './TagModel';
import { DateTime } from 'luxon';

export class ParticipationResumeModel {

  // Group
  public id: number | null;
  public nbParticipants: number = 1;
  public groupName: string = '';

  public validationStatus: ParticipationStatus;
  public validationMessage = '';
  public timeSlot: TimeSlotResumeModel;
  public customAnswers: CustomAnswerResumeModel[] = [];
  public tags: TagModel[] = [];


  constructor({
    id = null,
    timeSlot = new TimeSlotResumeModel(),
    nbParticipants = 1,
    groupName = '',
    customAnswer = [],
    validationStatus = ParticipationStatus.ACCEPTED,
    validationMessage = '',
    eventTimeZone = '',
    tags = [],
  }: {
    id?: number | null,
    timeSlot?: TimeSlotResumeModel,
    nbParticipants?: number,
    groupName?: string,
    customAnswer?: CustomAnswerResumeModel[],
    validationStatus?: ParticipationStatus,
    validationMessage?: string,
    eventTimeZone?: string,
    tags?: TagModel[],
  } = {}) {
    this.id = id;
    this.nbParticipants = nbParticipants;
    this.groupName = groupName;

    if (timeSlot instanceof TimeSlotResumeModel) {
      this.timeSlot = timeSlot;
    } else {
      (timeSlot as any).startTime = DateTime.fromJSDate((timeSlot as any).startTime).setZone(eventTimeZone);
      (timeSlot as any).endTime = DateTime.fromJSDate((timeSlot as any).endTime).setZone(eventTimeZone);
      this.timeSlot = new TimeSlotResumeModel(timeSlot);
    }


    this.validationStatus = validationStatus;
    this.validationMessage = validationMessage;

    this.customAnswers = customAnswer.map((d: any): CustomAnswerResumeModel => {
      if (d instanceof CustomAnswerResumeModel) {
        return d;
      } else {
        return new CustomAnswerResumeModel(d);
      }
    });

    this.tags = tags.map((d: any): TagModel => {
      if (d instanceof TagModel) {
        return d;
      } else {
        return new TagModel(d);
      }
    });
  }

  public get locationName() {
    return this.timeSlot.printName;
  }
  public get description() {
    if (!this.timeSlot.sectorDescription && !this.timeSlot.positionDescription) {
      return null;
    }

    let desc = this.timeSlot.positionDescription;

    if (this.timeSlot.sectorDescription) {
      desc = this.timeSlot.sectorDescription;
      if (this.timeSlot.positionDescription) {
        desc += '\n\n | ' + this.timeSlot.positionDescription;
      }

    }
    return desc;
  }

  public get shiftDay() {
    return this.timeSlot.startDateFormatted;
  }
}
