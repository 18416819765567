import axios, { AxiosInstance } from 'axios';
import { AUTHENTICATION_REJECT_INTERCEPTOR, AUTHENTICATION_USE_INTERCEPTOR } from '@/interceptors';
import router from '@/router';
import { AuthenticationService } from '@/services';

export class OrganizerAPI {

  private static internal: AxiosInstance;
  private static isoDateRegex = new RegExp(
    '^(\\d{4})[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$',
  );
  private static isoDateTimeRegex = new RegExp(
    '^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T' +
    '(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z|\\+\\d{2}:\\d{2})?$',
  );

  private static isoDurationRegex = new RegExp(
    '^(-?)P(?=\\d|T\\d)(?:(\\d+)Y)?(?:(\\d+)M)?(?:(\\d+)([DW]))?' +
    '(?:T(?:(\\d+)H)?(?:(\\d+)M)?(?:(\\d+(?:\\.\\d+)?)S)?)?$',
  );

  public static get instance(): AxiosInstance {
    if (!OrganizerAPI.internal) {
      OrganizerAPI.internal = axios.create({
        baseURL: process.env.VUE_APP_ORGANIZER_API_URL,
        transformResponse: (data?: any, headers?: any): any => {
          if (data !== undefined && data !== null && data !== '') {
            return JSON.parse(data, (key: any, value: any): any => {
              if (typeof value === 'string') {
                const dateTimeMatches = OrganizerAPI.isoDateTimeRegex.exec(value as string);
                if (dateTimeMatches !== null) {
                  return new Date(value);
                }
                const dateMatches = OrganizerAPI.isoDateRegex.exec(value as string);
                if (dateMatches !== null) {
                  return new Date(value);
                }
                const durationMatches = OrganizerAPI.isoDurationRegex.exec(value as string);
                if (durationMatches !== null) {
                  // Only handle hour minutes and seconds
                  return parseInt(durationMatches[4], 10) * 24 * 60 * 60 +
                    parseInt(durationMatches[6], 10) * 60 * 60 +
                    parseInt(durationMatches[7], 10) * 60 +
                    parseInt(durationMatches[8], 10);
                }
              }
              return value;
            });
          } else {
            return {};
          }
        },
      });
    }
    OrganizerAPI.internal.interceptors.request.use(
      AUTHENTICATION_USE_INTERCEPTOR,
      AUTHENTICATION_REJECT_INTERCEPTOR,
    );
    // Add a response interceptor
    OrganizerAPI.internal.interceptors.response.use((response) => {
      // Do something with response data
      return response;
    }, (error) => {
      if (error.response && (error.response.status === 403 || error.response.status === 401)) {
        AuthenticationService.instance.cleanCookies();
        router.push({ name: 'app-public-login' });
      }
      // Do something with response error
      return Promise.reject(error);
    });
    return OrganizerAPI.internal;
  }

}
