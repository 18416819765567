import { DateUtils } from '@/utils/date';
import { ParticipationModel } from './ParticipationModel';
import { TimeSlotModel } from './TimeSlotModel';
import { EventModel } from './EventModel';
import { CampaignRegistrationResumeModel } from './CampaignRegistrationResumeModel';
import { VolunteerCategoryModel } from './VolunteerCategoryModel';
import { CampaignAnswersResumeModel } from './CampaignAnswersResumeModel';
import { EventDayModel } from './EventDayModel';

export class VolunteerModel {

  public static copy(vol: VolunteerModel): VolunteerModel {
    const vol2 = new VolunteerModel();
    vol2.id = vol.id;
    vol2.firstName = vol.firstName;
    vol2.lastName = vol.lastName;
    vol2.email = vol.email;
    vol2.phoneNumber = vol.phoneNumber;
    vol2.bornDate = vol.bornDate;
    vol2.commentaire = vol.commentaire;
    vol2.participationIds = vol.participationIds;
    vol2.participations = vol.participations;
    vol2.numberSlots = vol.numberSlots;
    vol2.tshirt = vol.tshirt;
    vol2.categories = vol.categories;
    vol2.categoryIds = vol.categoryIds;
    vol2.address = vol.address;
    vol2.town = vol.town;
    vol2.postcode = vol.postcode;

    return vol2;
  }

  public id: number | null = null;
  public firstName: string;
  public lastName: string;
  public email: string;
  public phoneNumber: string;
  public address: string;
  public town: string;
  public postcode: string;
  public tshirt: string;
  public commentaire: string;
  public bornDate: Date | null;
  public participationIds: number[];
  public organizationId: number | null = null;
  public participations: ParticipationModel[] = [];
  public campaignResume: CampaignRegistrationResumeModel[] = [];
  public campaignAnswers: CampaignAnswersResumeModel[] = [];
  public numberSlots = 0;
  public createdAt: Date | null;
  public categories: VolunteerCategoryModel[] = [];
  public categoryIds: number[];
  public nbVouchersAllowed: number = 0;
  public profilePicture: string;

  // Testing for assigner
  public isSelectedOnList = false;

  public pointsBalance: number | null = null;

  constructor({
    id = null,
    organizationId = null,
    firstName = '',
    lastName = '',
    email = '',
    phoneNumber = '',
    address = '',
    profilePicture = '',
    town = '',
    postcode = '',
    bornDate = null,
    commentaire = '',
    tshirt = '',
    participationIds = [],
    createdAt = null,
    categories = [],
    categoryIds = [],
    numberSlots = 0,
    nbVouchersAllowed = 0,
    pointsBalance = null,
  }: {
    id?: number | null,
    organizationId?: number | null,
    firstName?: string,
    lastName?: string,
    email?: string,
    phoneNumber?: string,
    address?: string,
    town?: string,
    profilePicture?: string,
    postcode?: string,
    bornDate?: Date | null,
    commentaire?: string,
    tshirt?: string,
    participationIds?: number[],
    createdAt?: Date | null,
    categories?: VolunteerCategoryModel[],
    categoryIds?: number[],
    numberSlots?: number,
    nbVouchersAllowed?: number,
    pointsBalance?: null | number,
  } = {}) {
    this.id = id;
    this.organizationId = organizationId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.profilePicture = profilePicture;
    this.address = address;
    this.town = town;
    this.postcode = postcode;
    this.tshirt = tshirt;
    this.commentaire = commentaire;
    this.bornDate = bornDate;
    this.participationIds = participationIds;
    this.createdAt = createdAt;
    this.categories = categories;
    this.numberSlots = numberSlots;
    this.categoryIds = categoryIds;
    this.nbVouchersAllowed = nbVouchersAllowed;
    this.pointsBalance = pointsBalance;
  }

  public toJSON(): any {
    return {
      id: this.id,
      organizationId: this.organizationId,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phoneNumber: this.phoneNumber,
      address: this.address,
      town: this.town,
      postcode: this.postcode,
      bornDate: DateUtils.dateToISODate(this.bornDate),
      commentaire: this.commentaire,
      participationIds: this.participationIds,
      categories: this.categories.map((category) => category.id),
      categoriesIds: this.categoryIds,
      tshirt: this.tshirt,
    };
  }

  public toPublicJSON(): any {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phoneNumber: this.phoneNumber,
      address: this.address,
      town: this.town,
      postcode: this.postcode,
      bornDate: DateUtils.dateToISODate(this.bornDate),
      tshirt: this.tshirt,
    };
  }


  public get orderedParticipations(): ParticipationModel[] {
    const ordered = this.participations.slice().sort((a, b) => {
      return a.timeSlot.startTime.toMillis() - b.timeSlot.startTime.toMillis();
    });
    return ordered;
  }

  public get timeslots(): TimeSlotModel[] {
    const res: TimeSlotModel[] = [];
    if (this.orderedParticipations) {
      this.orderedParticipations.forEach((iresume) => {
        const detail = iresume.timeSlot;
        detail.participationId = iresume.id;
        detail.eventName = iresume.eventName;
        detail.eventId = iresume.eventId;
        detail.patisserie = iresume.patisserie;
        detail.referenceMemberFirstName = iresume.referenceMemberFirstName;
        detail.referenceMemberLastName = iresume.referenceMemberLastName;
        detail.customAnswers = iresume.customAnswers;
        detail.createdAt = iresume.createdAt;
        res.push(detail);
      });
    }
    return res;
  }

  public get age() {
    if (!this.bornDate) {
      return null;
    }

    const today = new Date();
    let age = today.getFullYear() - this.bornDate.getFullYear();
    const m = today.getMonth() - this.bornDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < this.bornDate.getDate())) {
      age--;
    }

    if (age >= 18) {
      return '+18';
    } else if (age >= 16) {
      return '16-18';
    } else if (age >= 14) {
      return '14-16';
    } else if (age < 14) {
      return '-14';
    }

    return age;
  }

  public get groupDurations() {
    let duration = 0;
    this.participations.filter((p) => p.nbParticipants > 1).forEach((participation) => {
      duration += participation.duration;
    });
    return duration;
  }

  public get duration() {
    let duration = 0;
    this.participations.forEach((p) => {
      duration += p.duration;
    });
    return duration;
  }

  public containsGroup(): boolean {
    return this.participations.find((p) => p.nbParticipants > 1) !== undefined;
  }
  public groupNames(): string[] {
    return [...new Set(this.participations.map((p) => p.groupName))];
  }

  public getTimeSlotsForDay(eventDay: EventDayModel, inEvent: EventModel): TimeSlotModel[] {
    const ts: TimeSlotModel[] = [];
    this.participations.forEach((part) => {
      ts.push(...part.getTimeSlotIdsForCurrentDay(eventDay, inEvent));
    });
    return ts;
  }

  public getParticipationsForDay(eventDay: EventDayModel, inEvent: EventModel, removedRejected: boolean = false): ParticipationModel[] {
    const parts: ParticipationModel[] = [];
    if (removedRejected) {
      this.participations.filter((part) => {
        return part.validationStatus === 0 || part.validationStatus === 1 || part.validationStatus === 6;
      }).forEach((part) => {
        if (part.getTimeSlotIdsForCurrentDay(eventDay, inEvent).length > 0) {
          parts.push(part);
        }
      });

    } else {
      this.participations.forEach((part) => {
        if (part.getTimeSlotIdsForCurrentDay(eventDay, inEvent).length > 0) {
          parts.push(part);
        }
      });
    }
    return parts;
  }

}
