import { CampaignFieldModel } from './CampaignFieldModel';

export enum CampaignStatus {
  Draft = 'draft',
  Published = 'published',
  Closed = 'closed',
  Archived = 'archived',
}

export class CampaignModel {

  public static empty() {
    return new CampaignModel();
  }

  public id: string | null = null;
  public organization: number | null = null;
  public name: string;
  public description: string;
  public contactName: string;
  public contactEmail: string;
  public fields: CampaignFieldModel[];
  public logo: string;
  public base64Logo: string;
  public emailMandatory: boolean;
  public askForPhone: boolean;
  public phoneMandatory: boolean;
  public askForBirthdate: boolean;
  public isPublic: boolean;
  public registrationEmailText: string;
  public status: CampaignStatus;
  public nbRegistrations: number;
  public token: string;
  public askForAddress: boolean;
  public addressMandatory: boolean;
  public askForShirtSize: boolean;
  public shirtSizeMandatory: boolean;
  public showOnHomeCalendar: boolean;

  public timeZone: string = '';

  constructor({
    id = null,
    organization = null,
    token = '',
    name = '',
    description = '',
    contactName = '',
    contactEmail = '',
    fields = [],
    logo = '',
    timeZone = 'Europe/Zurich',
    base64Logo = '',
    status = CampaignStatus.Draft,
    nbRegistrations = 0,
    emailMandatory = false,
    askForPhone = false,
    phoneMandatory = false,
    askForBirthdate = false,
    askForAddress = false,
    addressMandatory = false,
    askForShirtSize = false,
    shirtSizeMandatory = false,
    showOnHomeCalendar = false,
    isPublic = false,
    registrationEmailText = 'Hello [[volunteer_name]],\n\n\
Merci pour votre inscription à [[campaign_name]] !\n\n\
[[campaign_registration_summary]]\n\n\
En cas de questions, vous pouvez vous adresser à [[campaign_coordinator_name]] par email : [[campaign_coordinator_email]].\n\n\
Merci, sans vous, nous ne pourrions mettre sur pied notre manifestation. :-)\n\n',
  }: {
    id?: string | null,
    organization?: number | null,
    token?: string,
    name?: string,
    description?: string,
    contactName?: string,
    contactEmail?: string,
    fields?: any[],
    logo?: string,
    base64Logo?: string,
    status?: CampaignStatus,
    nbRegistrations?: number
    emailMandatory?: boolean,
    askForPhone?: boolean,
    phoneMandatory?: boolean,
    askForBirthdate?: boolean,
    askForAddress?: boolean,
    addressMandatory?: boolean,
    askForShirtSize?: boolean,
    shirtSizeMandatory?: boolean,
    isPublic?: boolean,
    timeZone?: string,
    registrationEmailText?: string,
    showOnHomeCalendar?: boolean,
  } = {}) {
    this.id = id;
    this.organization = organization;
    this.token = token;
    this.name = name;
    this.logo = logo;
    this.base64Logo = base64Logo;
    this.status = status;
    this.nbRegistrations = nbRegistrations;
    this.description = description;
    this.contactName = contactName;
    this.contactEmail = contactEmail;
    this.emailMandatory = emailMandatory;
    this.askForPhone = askForPhone;
    this.phoneMandatory = phoneMandatory;
    this.askForBirthdate = askForBirthdate;
    this.askForAddress = askForAddress;
    this.addressMandatory = addressMandatory;
    this.askForShirtSize = askForShirtSize;
    this.shirtSizeMandatory = shirtSizeMandatory;
    this.isPublic = isPublic;
    this.timeZone = timeZone;
    this.showOnHomeCalendar = showOnHomeCalendar;
    this.registrationEmailText = registrationEmailText;
    this.fields = fields.map((f: any): CampaignFieldModel => {
      if (f instanceof CampaignFieldModel) {
        f.timeZone = this.timeZone;
        return f;
      } else {
        const field = new CampaignFieldModel(f);
        field.timeZone = this.timeZone;
        return field;
      }
    });
  }

  public toJSON(): any {
    return {
      id: this.id,
      organization: this.organization,
      token: this.token,
      name: this.name,
      description: this.description,
      contactName: this.contactName,
      contactEmail: this.contactEmail,
      fields: this.fields.map((f: CampaignFieldModel): any => f.toJSON()),
      base64Logo: this.base64Logo,
      status: this.status,
      emailMandatory: this.emailMandatory,
      askForPhone: this.askForPhone,
      phoneMandatory: this.phoneMandatory,
      askForBirthdate: this.askForBirthdate,
      isPublic: this.isPublic,
      registrationEmailText: this.registrationEmailText,
      showOnHomeCalendar: this.showOnHomeCalendar,
      askForAddress: this.askForAddress,
      addressMandatory: this.addressMandatory,
      askForShirtSize: this.askForShirtSize,
      shirtSizeMandatory: this.shirtSizeMandatory,
      timeZone: this.timeZone,
    };
  }

  public resetFieldsAnswers() {
    this.fields.forEach((f) => {
      f.userAnswer = [];
    });
  }

  public getFieldById(fid: number): CampaignFieldModel | undefined {
    return this.fields.find((f) => f.id === fid);
  }
}
