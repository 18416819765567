import { TimeSlotModel } from '@/models/TimeSlotModel';
import { TagModel } from '@/models/TagModel';
import IntervalTree from '@flatten-js/interval-tree';
import { ParticipationModel, ParticipationStatus } from './ParticipationModel';
import { DateTime } from 'luxon';


export class PositionModel {

  public static empty() {
    return new PositionModel({});
  }

  public copyValueFrom(source: PositionModel) {
    this.name = source.name;
    this.description = source.description;
    this.tags = source.tags;
    this.defaultParticipationValidationStatus = source.defaultParticipationValidationStatus;
    this._maxParticipantsAtTime = source._maxParticipantsAtTime;
  }
  public static clone(position: PositionModel) {
    const item = new PositionModel();
    item.id = position.id;
    item.copyValueFrom(position);
    return item;
  }

  public id: number | null = null;
  public name: string;
  public order: number;
  public description: string;
  public _maxParticipantsAtTime: null | number = null; // null = no max
  public aiFieldList: string[] = [];
  public tags: TagModel[] = [];

  private slotsTree: IntervalTree = new IntervalTree();
  private heightForDayIndex = new Map<string, number>();
  private slotsById: Map<number, TimeSlotModel> = new Map();

  public defaultParticipationValidationStatus: ParticipationStatus;
  public updateNeeded = false;

  constructor({
    id = null,
    name = '',
    order = 0,
    timeSlots = [],
    tags = [],
    description = '',
    defaultParticipationValidationStatus = ParticipationStatus.ACCEPTED,
    eventTimeZone = '',
    maxParticipantsAtTime = null,
  }: {
    id?: number | null,
    name?: string,
    order?: number,
    timeSlots?: any[],
    tags?: any[],
    description?: string,
    defaultParticipationValidationStatus?: ParticipationStatus,
    eventTimeZone?: string,
    maxParticipantsAtTime?: null | number,
  } = {}) {
    this.id = id;
    this.name = name;
    this.order = order;
    this._maxParticipantsAtTime = maxParticipantsAtTime;

    this.description = description;
    this.defaultParticipationValidationStatus = defaultParticipationValidationStatus;
    timeSlots.forEach((ts: any) => {
      if (ts instanceof TimeSlotModel) {
        ts.position = this;
        this.addSlot(ts, null, null);
      } else {
        ts.startTime = DateTime.fromJSDate(ts.startTime).setZone(eventTimeZone);
        ts.endTime = DateTime.fromJSDate(ts.endTime).setZone(eventTimeZone);
        let nTS = new TimeSlotModel(ts);
        nTS.position = this;
        this.addSlot(nTS, null, null);
      }
    });
    this.tags = tags.map((p: any): TagModel => {
      if (p instanceof TagModel) {
        return p;
      } else {
        return new TagModel(p);
      }
    });
  }

  public toJSON(): any {
    return {
      id: this.id,
      name: this.name,
      order: this.order,
      description: this.description,
      timeSlots: this.timeSlots.map((ts: TimeSlotModel): any => ts.toJSON()),
      tags: this.tags.map((tag: TagModel): any => tag.id),
      defaultParticipationValidationStatus: this.defaultParticipationValidationStatus,
      maxParticipantsAtTime: this._maxParticipantsAtTime,
    };
  }

  public get maxParticipantsAtTime(): number {
    if (this._maxParticipantsAtTime) {
      return this._maxParticipantsAtTime;
    } else {
      return 0;
    }
  }

  public set maxParticipantsAtTime(val: number) {
    console.log(`set ${val}`);

    if (val == 0) {
      this._maxParticipantsAtTime = null;
    } else {
      console.log('^');
      this._maxParticipantsAtTime = val;
    }
    console.log(this);

  }

  public get timeSlots(): TimeSlotModel[] {
    return this.slotsTree.values;
  }

  public set timeSlots(timeSlots: TimeSlotModel[]) {

    this.slotsById = new Map();
    timeSlots.forEach((slot) => {
      this.slotsTree.insert([slot.startTime.toMillis(), slot.endTime.toMillis()], slot);
      if (slot.id) {
        this.slotsById.set(slot.id, slot);
      }
    });
  }

  public get numberVolunteersRequired() {
    if (this.timeSlots.length > 0) {
      return this.timeSlots.map((ts) => Number(ts.requiredVolunteerCount)).reduce((p, n) => p + n);
    }
    return 0;
  }
  public getCurrentParticipantNumber(participations: ParticipationModel[]): number {
    if (this.timeSlots.length > 0) {
      return this.timeSlots.map((ts) => ts.getCurrentParticipantNumber(participations)).reduce((p, n) => p + n);
    }
    return 0;
  }


  public addSlot(slot: TimeSlotModel, forRangeStart: DateTime | null, rangeEnd: DateTime | null) {
    this.slotsTree.insert([slot.startTime.toMillis(), slot.endTime.toMillis()], slot);
    if (slot.id) {
      this.slotsById.set(slot.id, slot);
    }
    if (forRangeStart !== null && rangeEnd !== null) {
      const key = forRangeStart.toMillis() + '-' + rangeEnd.toMillis();
      if (this.heightForDayIndex.has(key)) {
        this.heightForDayIndex.delete(key);
      }
    }
  }

  public removeSlot(slot: TimeSlotModel, forRangeStart: DateTime | null, rangeEnd: DateTime | null) {
    this.slotsTree.remove([slot.startTime.toMillis(), slot.endTime.toMillis()], slot);
    if (slot.id) {
      this.slotsById.delete(slot.id);
    }
    this.heightForDayIndex.clear();
    if (forRangeStart && rangeEnd) {
      this.defineSlotYIndex(forRangeStart, rangeEnd, true);
    }
  }

  public defineSlotYIndex(forRangeStart: DateTime, rangeEnd: DateTime, withPrivate: boolean): number {
    let height = 1;

    this.slotsInRange(forRangeStart, rangeEnd, withPrivate).forEach((slot) => {
      const slotOverlaps = this.slotsTree.search([slot.startTime.toMillis() + 1, slot.endTime.toMillis() - 1]);

      for (let index = 0; index < slotOverlaps.length; index++) {
        if (index > 0) {
          slotOverlaps[index].currentYIndex = slotOverlaps[index - 1].currentYIndex + 1;
        } else {
          slotOverlaps[index].currentYIndex = 0;
        }
      }
      height = Math.max(slotOverlaps.length, height);
    });
    return height;
  }

  public getTimeLineNumberOfItems(forRangeStart: DateTime, rangeEnd: DateTime, withPrivate: boolean, dynamic: boolean = false): number {
    // si aucun ou 1 seul slot => 1 hauteur
    if (this.slotsTree.size <= 1) {
      this.heightForDayIndex.clear();
      return 1;
    }

    const key = forRangeStart.toMillis() + '-' + rangeEnd.toMillis();
    // Check cache
    if (this.heightForDayIndex.has(key)) {
      return Number(this.heightForDayIndex.get(key));
    }

    let height = 1;

    if (this.slotsTree.size > 1) {

      this.slotsInRange(forRangeStart, rangeEnd, withPrivate).forEach((slot) => {
        const slotOverlaps = this.slotsTree.search([slot.startTime.toMillis() + 1, slot.endTime.toMillis() - 1]);

        if (slotOverlaps.length === 0) {
          slot.currentYIndex = 0;
        } else {
          const positionUsed: number[] = [];

          slotOverlaps.forEach((so) => {
            if (so.id === slot.id) {
              for (let index = 0; index < slotOverlaps.length; index++) {
                if (!positionUsed.includes(index)) {
                  slot.currentYIndex = index;
                  break;
                }
              }
              positionUsed.push(slot.currentYIndex);
            } else {
              positionUsed.push(so.currentYIndex);
            }
          });
        }
        height = Math.max(slot.currentYIndex + 1, height);
      });
    }
    this.heightForDayIndex.set(key, height);
    return height;
  }






  public getTimeLineHeight(forRangeStart: DateTime, rangeEnd: DateTime, withPrivate: boolean, dynamic: boolean = false, displayNames: boolean = false): number {
    // si aucun ou 1 seul slot => 1 hauteur
    // if (this.slotsTree.size <= 1) {
    //   this.heightForDayIndex.clear();
    //   return 20;
    // }

    const key = `${forRangeStart.toMillis()}-${rangeEnd.toMillis()}-${displayNames}`;
    // Check cache
    if (this.heightForDayIndex.has(key)) {
      return Number(this.heightForDayIndex.get(key));
    }

    //Default
    let height = 0;

    if (this.slotsTree.size > 0) {

      this.slotsInRange(forRangeStart, rangeEnd, withPrivate).forEach((slot) => {
        const slotOverlaps = this.slotsTree.search([slot.startTime.toMillis() + 1, slot.endTime.toMillis() - 1]);
        // console.log('----');
        // console.log(`slot : ${slot.timeInfo}`);

        if (slotOverlaps.length === 1) {
          slot.currentYIndex = 0;
          height = slot.currentYIndex + slot.getSlotHeight(displayNames);
        } else {
          const positionUsed: number[] = [];

          let marginTop = 0;
          slotOverlaps.forEach((so) => {
            // console.log(`\t slot : ${so.timeInfo}`);
            // console.log(`\t slot - currentYIndex: ${so.currentYIndex}`);
            // console.log(`\t slot - height : ${so.getSlotHeight(displayNames)}`);


            if (so.id === slot.id) {
              slot.currentYIndex = marginTop;
              height = height + so.getSlotHeight(displayNames);
              // console.log(`slot.currentYIndex : ${slot.currentYIndex}`);
              // console.log(`slot.height : ${slot.getSlotHeight(displayNames)}`);
            } else {
              marginTop = so.currentYIndex + so.getSlotHeight(displayNames);
              // console.log(`\t marginTop -> ${marginTop}`);
            }

          });
        }
        // console.log(`Return ${slot.currentYIndex} vs ${height}`);

        height = Math.max(slot.currentYIndex + 1, height);

        if (this.heightForDayIndex.has(key)) {
          height = Math.max(height, Number(this.heightForDayIndex.get(key)));
        }
        this.heightForDayIndex.set(key, height);
      });
    }

    return height;
  }


  public countNumberOfSlotOverlapTree(slot: TimeSlotModel): number {
    return this.slotsTree.search([slot.startTime.toMillis() + 1, slot.endTime.toMillis() - 1]).length;
  }



  public didContainSlotInDateRange(startDate: DateTime, endDate: DateTime, withPrivate: boolean): boolean {
    return this.slotsInRange(startDate, endDate, withPrivate).length > 0;
  }

  public getFirstSlot(): DateTime {
    return this.orderedTimeSlots[0].startTime;
  }

  public getLastSlot(): DateTime {
    return this.orderedTimeSlots[this.orderedTimeSlots.length - 1].endTime;
  }


  public slotsInRange(startDate: DateTime, endDate: DateTime, withPrivate: boolean): TimeSlotModel[] {

    let slots: TimeSlotModel[] = [];
    let startMillis = startDate.toMillis();
    let endMillis = endDate.toMillis();


    if (withPrivate) {
      slots = this.orderedTimeSlots.filter((slot) => slot.isIncludedOnRange(startDate, endDate));
    } else {
      slots = this.orderedPublicTimeSlots.filter((slot) => slot.isPrivate === false &&
        slot.isIncludedOnRange(startDate, endDate));
    }

    return slots;
  }


  public get orderedTimeSlots(): TimeSlotModel[] {
    const orderedArray = this.timeSlots.slice().sort(function compare(a, b) {
      return a.startTime.toMillis() - b.startTime.toMillis();
    });
    return orderedArray;
  }

  public get orderedPublicTimeSlots(): TimeSlotModel[] {
    const publicSlots: TimeSlotModel[] = new Array();
    for (const slot of this.timeSlots) {
      if (!slot.isPrivate) {
        publicSlots.push(slot);
      }
    }
    const orderedArray = publicSlots.slice().sort(function compare(a, b) {
      return a.startTime.toMillis() - b.startTime.toMillis();
    });
    return orderedArray;
  }

  public slotWithId(slotId: number): TimeSlotModel | null {
    return this.slotsById.get(slotId) || null;
  }

  public debugTree() {
    console.log(this.name);
    console.log(this.slotsTree);
  }



  ////// TAG LOGIC

  public get privateTags() {
    return this.tags.filter((t) => t.visibility === 0);
  }
  public get publicTags() {
    return this.tags.filter((t) => t.visibility === 1);
  }

  public tagSearch = '';
  public get criteria() {
    // Compute the search criteria
    return this.tagSearch.trim().toLowerCase();
  }
  public get listOfTagsId() {
    return this.tags.map((tag) => Number(tag.id));
  }

  public availableTags(baseList: TagModel[]): string[] {

    const options = baseList.map((o) => o.fullName);

    // Filter out already selected options
    options.filter(opt => this.tags.map((opt) => opt.fullName).indexOf(opt) === -1);

    if (this.criteria) {
      // Show only options that match criteria
      return options.filter(opt => opt.toLowerCase().indexOf(this.criteria) > -1);
    }
    // Show all options available
    return options;
  }

  public get tagsListwFullName(): string[] {
    return this.tags.map((opt) => opt.fullName).sort();
  }

  public getTagStyle(tagName: string) {
    let style = `background-color:#fff; color:#000`;
    this.tags.find((tag) => {
      if (tag.fullName === tagName) {
        style = tag.style;
      }
    });
    return style;
  }

  public set tagsListwFullName(list: string[]) {
    const deletedTags = this.tagsListwFullName.filter(opt => list.indexOf(opt) === -1);
    deletedTags.forEach((tag) => {
      this.removeTagByFulleName(tag);
    });
  }

  public addTagByFullName(fullName: string, baseList: TagModel[]) {
    const tag = baseList.find((tag) => { return tag.fullName === fullName });
    if (tag) {
      this.tags.push(tag);
    }
  }
  public removeTagByFulleName(fullName: string, updateNeeded: boolean = false) {
    const index = this.tags.findIndex((tag) => { return tag.fullName === fullName });
    if (index !== -1) {
      this.tags.splice(index, 1);
      this.updateNeeded = updateNeeded;
    }
  }
}
